<template>
  <div>
    <SubHeader :type="t('projectPreferences.bar', {}, { locale: $store.state.Langs.selected.lang })" />
    <div class="container-xl px-2 py-2 mx-auto w-full my-4 flex flex-col">
      <div class="grid md:grid-cols-5 h-auto pb-10 gap-4">
        <div class="md:col-span-1">
          <SidebarProject />
        </div>
        <TransitionFade>
          <div class="w-full md:col-span-4 h-auto">
            <Card class="w-full border border-gray-400">
              <template #header>
                <div class="bg-[#1F3121] px-4 py-2 rounded-t-md border-b border-gray-300">
                  <div class="flex-center space-x-4">
                    <div class="flex-center bg-white rounded-md shadow px-2">
                      <Icon icon="tabler:chart-line" class="text-gray-800 w-7 h-7" />
                    </div>
                    <div class="flex-1">
                      <span class="text-white block text-xl font-semibold">{{ $t("projectPreferences.salesChart") }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <template #content>
                <div class="flex flex-col w-full">
                  <div class="w-full grid grid-cols-1">
                    <div class="chartContainer h-80 flex justify-start items-center">
                      <TimeLineSkeleton v-if="chartLoader" />
                      <ECharts v-if="!chartLoader" class="h-full w-full" ref="chart" :option="salesChart.option" />
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </TransitionFade>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import SubHeader from "@/components/SubHeader"
import TransitionFade from "@/components/TransitionFade"
import * as echarts from "echarts"
import { h } from "vue"
import { createComponent } from "echarts-for-vue"
import { ChartsConfig } from "@/config/ChartsConfig"
import TimeLineSkeleton from "@/components/Dashboard/TimeLineSkeleton"
import SidebarProject from "@/components/SidebarProject"

import Card from "primevue/card"
import { Icon } from "@iconify/vue"

export default {
  name: `Project Profile`,
  components: {
    SubHeader,
    TransitionFade,
    ECharts: createComponent({ echarts, h }),
    SidebarProject,
    TimeLineSkeleton,
    Card,
    Icon
  },
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  data() {
    return {
      currentProject: this.$route.params.id ? this.$route.params.id : null,
      salesChart: {},
      chartLoader: true
    }
  },
  created() {
    if (this.currentProject) {
      this.getChartInfo()
    }
  },
  methods: {
    getChartInfo: async function () {
      let currentProject = this.currentProject
      await this.$store.dispatch("Dashboard/salesChart", {
        currentProject,
        params: {
          group_by: "year,month",
          order_by: "year,month"
        }
      })
      this.chartLoader = false
      this.salesChart = ChartsConfig.salesChart(this.$store.state.Dashboard)
    }
  }
}
</script>
